.star {
  position: relative;
  z-index: 5;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.container {
  position: relative;
}
