.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px 45px;
  width: 100%;
  margin-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

