.body {
  margin-top: 30px;
  display: block;
  position: relative;
  width: 100%;
  padding-left: 450px;
}

.body.collapsed .explainerCardContainer {
  width: 450px;
}

.list {
  display: flex;
  flex-flow: column nowrap;
  gap: 30px;
  opacity: 0;
  position: relative;
  z-index: 4;
  visibility: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-left: 30px;
  list-style: none;
  min-height: 435px;
}
.list.animate {
  transition: opacity 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.list.collapsed {
  opacity: 1;
  visibility: visible;
}

.explainerCard {
  width: 100%;
  max-width: 450px;
  margin: auto;
  overflow: hidden;
  border-color: var(--borders);
  background: var(--cards_2);
}

.explainerCardContainer {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 5;
  will-change: width;
}

.explainerCardContainer.animate {
  transition: width 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* tablet */
@media screen and (max-width: 930px) {
  .body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
  }

  .body .explainerCardContainer {
    width: 100%;
    position: static;
  }

  .list {
    min-height: unset;

    opacity: 1;
    max-width: 550px;
    margin: auto;
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
}
