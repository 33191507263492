.loader {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--white);
}

.loaderContent {
  max-width: 550px;
  margin: auto;
}

.iFrame {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--white);
}
