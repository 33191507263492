.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin: 10px 0 0 0;
  border-top: solid 1px var(--borders_1);
  border-bottom: solid 1px var(--borders_1);
}

.header.boxedOutput,
.header.noBorders {
  padding: 0;
  margin: 0;
  border: none;
}

.results {
  padding: 30px 0;
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  border-bottom: solid 1px var(--borders_1);
  width: 100%;
}

.results.boxedOutput {
  padding: 20px;
  padding-top: 26px;
  border: solid 1px var(--borders_1);
  border-top: none;
  margin-top: -6px;
  background: var(--background);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.results.noBorders {
  border-bottom: none;
}

.emptyState {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px var(--borders_1);
}

.emptyState.noBorders {
  border-bottom: none;
}
