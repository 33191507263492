.tooltipWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background: var(--background);
  color: var(--text);
  border: 1px solid var(--borders);
  border-radius: 10px;
  padding: 10px;
}

.tooltipText {
  display: flex;
  align-items: center;
  gap: 8px;
}

