.bulkUploadHeader {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  
  .bulkUploadHeader a {
    color: var(--link-text);
    text-decoration: none;
    cursor: pointer;
  }
  

  /* todo rename styling */