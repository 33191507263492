.background {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  background: url('/public/planets/background.png');
  background-size: 100%;
  background-repeat: repeat-y;
  padding-bottom: 90px;
}

.sun {
  display: block;
  height: 380px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sun object,
.sun img {
  position: absolute;
  left: 0;
  bottom: 80px;
  width: 100%;
  height: auto;
}

.header {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  padding-top: 40px;
  padding-left: calc(10% / 2);
  padding-right: calc(10% / 2);
  background: linear-gradient(180deg, var(--dark-background) 0%, var(--dark-background_1) 60%, var(--dark-background_1) 90%, var(--dark-background_2) 97%, transparent 100%);
}

.page {
  --dark-background: hsla(224, 52%, 10%, 1);
  --dark-background_1: hsla(224, 52%, 10%, 0.75);
  --dark-background_2: hsla(224, 52%, 10%, 0.3);

  position: relative;
  background: var(--dark-background);
  min-height: 100vh;
}
