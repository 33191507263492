.raterExtraRatingCounterText {
  display: block;
  padding-bottom: 5px;
  font-weight: bold;
  color: var(--accent-2);
}

.raterExtraRatingCounter {
  text-align: center;
}

@media screen and (max-width: 580px) {
  .raterExtraRatingCounterText {
    padding-bottom: 0;
  }

  .raterExtraRatingCounter {
    display: flex;
    align-items: center;
    gap: 25px;
  }
}

@media screen and (max-width: 580px) {
  .raterExtraRating {
    margin-left: auto;
  }
}

.raterButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

@media screen and (max-width: 580px) {
  .raterButtons {
    flex-flow: column nowrap;
    align-items: stretch;
  }
}

.raterHeader {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.raterHeader.disabled {
  opacity: 0.5;
}

.rater {
  padding: 35px 0;
  border-bottom: solid 1px var(--borders);
}

.rater:not(:first-child) {
  border-top: solid 1px var(--borders);
}

.rater.fade {
  opacity: 0.45;
}
