.donutGridItem {
  padding-top: 24px;
}

.gridItem {
  border-left: 1px solid var(--borders);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skillLevelItem {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 5px;
}

@media screen and (max-width: 1280px) {
  .gridItem {
    justify-content: flex-start;
  }

  .secondGridItem {
    border-left: none;
  }
}

@media screen and (max-width: 580px) {
  .gridItem {
    border-left: none;
    border-top: 1px solid var(--borders);
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .thirdGridItem {
    padding-bottom: 0px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 450px 1fr 1fr;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 1350px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}


@media screen and (max-width: 760px) {
  .grid {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .donutGridItem {
    padding: 10px 0;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
