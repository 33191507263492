.scrollContainer {
  max-height: 400px;
  overflow-y: auto;
}

.card {
  display: grid;
  grid-template-areas: 'name chip';
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
}

.chip {
  grid-area: chip;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

@media (max-width: 580px) {
  .card {
    grid-template-areas: 'name' 'chip';
    grid-template-columns: 1fr;
  }
}
