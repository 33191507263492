.avatar {
  width: 60px;
  height: 60px;
  margin: 15px auto 15px auto;
  border-radius: 50%;
  box-shadow: 0px 4px 8px var(--backdrop);
  overflow: hidden;
  border: 2px solid var(--accent-2);
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}

.card {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  box-shadow: 0px 4px 8px var(--backdrop);
  cursor: pointer;
}
