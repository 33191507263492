.cardTitle {
  text-align: left;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: ellipsis;
}

.cardContent {
  display: flex;
  align-items: left;
  margin-top: auto;
}

