/* child */
.sideNavPanelChildRouteList {
  padding: 10px 30px 0px 40px;

  /* animation */
  animation: SideNavPanelChildRouteListExpand 200ms ease forwards;
}

@keyframes SideNavPanelChildRouteListExpand {
  from {
    transform: scale(0, 0);
    transform-origin: top;
  }
  to {
    transform: scale(1, 1);
    transform-origin: top;
  }
}

/* parent */
.sideNavPanelParentRouteIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sideNavPanelParentRouteIcon.expanded {
  left: 10px;
}

.sideNavPanelParentRouteIcon.collapsed {
  left: 30px;
}

.sideNavPanelParentRouteTitle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sideNavPanelParentRouteTitle.expanded {
  left: 40px;

  color: var(--text);
  font-weight: 400;
}

.sideNavPanelParentRouteTitle.expanded.keplerActive {
  color: var(--contrast-text);
  font-weight: 700;
}

.sideNavPanelParentRouteContainer {
  position: relative;

  display: flex;
  align-items: center;

  width: 215px;
  height: 50px;
}

.sideNavPanelParentRouteContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  height: 100%;
  width: 0%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  background: var(--primary);

  will-change: width;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
}

.sideNavPanelParentRouteContainer.expanded {
  position: relative;

  animation: SideNavPanelRouteListSlideIn 600ms ease;
}

.sideNavPanelParentRouteContainer.expanded.keplerActive::before {
  width: 100%;
}

.sideNavPanelParentRouteContainer.collapsed {
  justify-content: center;
  width: 90px;

  animation: SideNavPanelRouteListSlideIn 200ms ease;
}

.sideNavPanelParentRouteContainer.collapsed:hover .sideNavPanelParentRouteTitle {
  opacity: 1;
}

.sideNavPanelParentRouteContainer.collapsed.keplerActive::before {
  width: 10px;
}

.sideNavPanelParentRouteNavLink {
  text-decoration: none;
}

.sideNavPanelParentRouteButton {
  background: none;
  border: none;
  cursor: pointer;
}

@keyframes SideNavPanelRouteListSlideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
