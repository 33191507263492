.personCard {
  display: grid;
  grid-template-columns: auto minmax(0px, 1fr);
  align-items: center;
  gap: 15px;
  padding: 15px;
}

.personCardIcon {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: solid 1px var(--text);
}

.personCardContent {
  display: flex;
  flex-flow: column;
  gap: 5px;
}
