.filtersGroup {
  display: grid;
  grid-template-areas: 'search sort filter';
  gap: 15px;
}

.filtersGroup :nth-child(1) {
  grid-area: search;
}

.filtersGroup :nth-child(2) {
  grid-area: sort;
}

.filtersGroup :nth-child(3) {
  grid-area: filter;
}

@media screen and (max-width: 850px) {
  .filtersGroup {
    width: 100%;
    grid-template-areas: 'search search' 'sort filter';
  }
}
