.conditionButton {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  text-align: left;
}

.conditionButton:disabled {
  cursor: not-allowed;
  border-color: var(--default);
}

.conditionButton:not(:disabled):hover {
  cursor: pointer;
  outline: 1px solid var(--text);
}

.heading {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.caption {
  color: var(--text_1);
}

@media (max-width: 580px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
  }
}
