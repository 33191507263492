.progressBar {
  display: block;
  padding: 10px 30px;
}

.navIcon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: none;
}

.navListItem {
  display: block;
  padding: 3px 40px 3px 0;
  margin: 0 0 10px 0;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--link-text);
  position: relative;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  will-change: font-weight;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
}

.navListItem:last-child {
  margin-bottom: 0;
}

.navListItem::after {
  content: '';
  display: block;
  width: 14px;
  height: 1px;
  background: var(--borders);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  will-change: width, background;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
}

.navListItem.activeSection {
  font-weight: 700;
  color: var(--text);
}

.navListItem.activeSection::after {
  background: var(--primary);
  width: 25px;
}

.navListItem.done {
  cursor: not-allowed;
}

.navListItem.done::after {
  display: none;
}

.navListItem.done .navIcon {
  display: block;
}

.navListItem:not(.done):hover::after {
  background: var(--accent-3);
  width: 18px;
}

.navList {
  display: block;
  padding: 10px 0 10px 0;
  margin: 0;
  list-style: none;
  margin-bottom: auto;
}

.nav {
  --sidenav-width: 375px;

  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: var(--sidenav-width);
  max-width: var(--sidenav-width);
  min-width: var(--sidenav-width);
  border-right: 1px solid var(--borders);
  padding-bottom: 10px;
}

.title {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.answer {
  opacity: 0;
  display: none;
  transform-origin: top left;
  pointer-events: none;
}

.answer.active {
  display: block;
  animation: FadeInAnswer ease-in-out 400ms forwards;
  pointer-events: all;
}

.answers {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 10px;
  position: relative;
}

.question {
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse nowrap;
  padding-top: 45px;
}

.container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid var(--borders);
  position: relative;
  z-index: 1;
}

.container.questionnaire {
  border: none;
  min-height: 100%;
  background: var(--background);
}

.container.questionnaire .nav {
  --sidenav-width: 300px;
  background: var(--cards);
}

.container.questionnaire .nav,
.container.questionnaire .question {
  padding-top: 130px;
}

.container.questionnaire .footer {
  padding-bottom: 50px;
}

/* tablet */
@media screen and (max-width: 930px) {
  .progressBar {
    padding: 10px 0;
  }

  .navListItem {
    font-size: 12px;
  }

  .navList {
    display: none;
  }

  .nav {
    --sidenav-width: 100%;
    border: none;
    background: none;
  }

  .title {
    height: 62px;
  }

  .answers {
    padding-left: 0;
  }

  .question {
    padding: 20px 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 25px 25px 25px;
    background: var(--background);
    width: 100%;
    border-top: solid 1px var(--borders);
  }

  .container {
    flex-flow: column-reverse nowrap;
    border: none;
  }

  .container.questionnaire {
    background: none;
  }

  .container.questionnaire .nav,
  .container.questionnaire .question {
    background: none;
  }
}

/* mobile */
@media screen and (max-width: 580px) {
  .question {
    padding: 0;
  }

  .container {
    padding-bottom: 80px;
  }
}

/* animation */
@keyframes FadeInAnswer {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
