.addButton {
  margin-top: 25px;
  width: 100%;
  height: 82px;
  background: var(--background);
  border: 1px solid var(--borders);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addButton:hover {
  border-color: var(--text);
}

.cardChipWrapper {
  position: absolute;
  top: -35px;
  left: 25px;
}

.removeButton {
  display: block;
  width: 50px;
  height: 50px;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  border-top-right-radius: 15px;
}

.removeButton::before,
.removeButton::after {
  content: '';
  display: block;
  width: 25px;
  height: 3px;
  background: var(--default);
  position: absolute;
  top: 12px;
  margin-left: calc(50px / 2 - 12px);
  border-radius: 2px;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 150ms;
}

.removeButton::before {
  transform: rotate(45deg);
}

.removeButton::after {
  transform: rotate(-45deg);
}

.removeButtonText {
  position: absolute;
  bottom: 4px;
  left: 1px;
  color: var(--default);
}

.removeButton:hover::before,
.removeButton:hover::after {
  background: var(--secondary);
}

.removeButton:hover .removeButtonText {
  color: var(--secondary);
}

.cardRemoveArea {
  grid-area: remove;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardIcon {
  width: 200px;
  height: 200px;
  background-color: var(--background);
  border-radius: 50%;
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIconArea {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTextArea {
  grid-area: text;
}

.cardBody {
  margin-left: 25px;
}

.card {
  padding: 25px;
  margin-top: 25px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100px 200px calc(100% - 300px);
  grid-template-areas: 'remove icon text';
}

/* mobile */
@media screen and (max-width: 580px) {
  .card {
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    grid-template-areas: 'text' 'remove';
  }

  .cardBody {
    margin-left: 0;
  }

  .cardIconArea {
    display: none;
  }
}
