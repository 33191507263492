.bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--cards);
}

.header {
  border-bottom: solid 1px var(--borders);
}

.button {
  border: 1px solid var(--primary);
  height: 100%;
  width: 320px;
  background-color: var(--primary);
  color: var(--contrast-text);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.footer {
  border-top: solid 1px var(--borders);
  justify-content: flex-end;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 24px;
  height: 90vh;
  width: 90vw;
  background: var(--background);
  overflow: hidden;
  opacity: 1;
  cursor: default;
  will-change: height, width, border-radius;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 90ms;
}

.dialog::backdrop {
  background-color: hsla(0, 0%, 0%, 0.8); /* TODO: try var() again in the future, not supported at the moment */
  backdrop-filter: blur(3px);
  cursor: pointer;
}

.dialog[open] {
  animation: FullscreenModalFadeIn 500ms ease forwards;
}

.dialog[open]::backdrop {
  animation: FullscreenModalFadeIn 500ms ease forwards;
}

.dialog.isFullscreen {
  height: 100%;
  width: 100%;
  border-radius: 0px;
}

/* touchscreen */
@media (pointer: coarse) {
  .button {
    width: 100%;
  }

  .footer {
    padding-left: 0px;
  }
}

/* animation */
@keyframes FullscreenModalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
