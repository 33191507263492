.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.header {
  position: relative;
  width: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.headerContent {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  justify-content: space-between;
  gap: 20px;
}

.textSection {
  position: relative;
  height: 100%;
  display: grid;
  padding: 20px;
  place-content: center start;
  gap: 10px;
}

.astronaut {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
}

.astronaut {
  height: auto;
}

.cardContent {
  padding: 20px;
}

.list {
  max-height: 400px;
  overflow-y: auto;
}

.skill {
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: auto 140px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .list {
    grid-template-columns: auto;
  }

  .headerContent {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
  }

  .textSection {
    position: relative;
    height: 100%;
    display: grid;
    padding: 0px;
    place-content: center start;
    gap: 10px;
  }

  .header {
    position: relative;
    width: 100%;
    padding: 0px;
    background-color: transparent;
  }
}
