
.cardColumn {
  width:200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scrollableList {
  max-height: 400px;
  overflow-y: auto;
}

.rowItem {
  flex: 0.6;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.cardStyle {
  border-top: 1px solid var(--borders);
  border-right: 1px solid var(--borders);
  border-bottom: 1px solid var(--borders);
  border-left: 4px solid green;
  display: flex;
  cursor: pointer;
}

.cardStyle:hover {
  border-top: 1.5px solid var(--borders);
  border-right: 1.5px solid var(--borders);
  border-bottom: 1.5px solid var(--borders);
  border-left: 4px solid green;
}

.cardStyleSelected {
  background-color: var(--borders_2);
  border-left: 4px solid green;
}

.card {
  border-left-width: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
