.card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cohort {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: start;
}

.cohort:hover {
  border-color: var(--contrast-text);
}

.cohort.selected {
  border-color: var(--secondary);
}
