.answer {
  line-height: 25px;
  padding-left: 25px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}

.answer:last-child {
  margin-bottom: 0;
}

.answer::after,
.answer::before {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.answer::before {
  display: block;
  width: 15px;
  height: 15px;
  border: solid 1px var(--link-text);
  left: 0px;
  top: 5px;
}

.answer::after {
  width: 11px;
  height: 11px;
  left: 2px;
  top: 7px;
  background: var(--link-text);
  transform: scale(0);
  opacity: 0;
  will-change: opacity, transform;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 200ms;
}

.answer.active::after {
  transform: scale(1);
  opacity: 1;
}

.answer.saving::before {
  border-width: 2px;
  animation: MultipleChoicePulse infinite 1400ms linear;
}

.answer.fade {
  opacity: 0.45;
  cursor: not-allowed;
}

/* animation */
@keyframes MultipleChoicePulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
