.overview {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.completionParticipants {
  display: grid;
  gap: 24px;
}

@media (max-width: 580px) {
  .overview {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .completionParticipants {
    grid-template-columns: 1fr;
  }
}
