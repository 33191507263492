.button {
  background: transparent;
  color: var(--secondary);
  border: none;
  text-align: center;
  text-align: center;
  align-items: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: auto;
}

.button svg {
  pointer-events: none;
  display: block;
  width: 100%;
  height: auto;
}

.input {
  width: 60px;
  height: 31px;
  background: var(--cards);
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  border: none;
  color: var(--text);
  font-weight: bold;
  font-size: 11px;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--heading);
  border: solid 1px var(--borders);
}

.container {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.counterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

