.container {
  position: relative;
  padding: 12px 40px;
  background-color: var(--background);
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 44px;
}

.steps {
  padding: 1px; /* ensures that outline isn't cut off */
  display: flex;
  justify-content: space-evenly;
  overflow-x: hidden;
}

/* default state */
.step {
  min-height: 44px;
  background-color: transparent;
  color: var(--text);
  white-space: nowrap;
  border: 2px solid transparent;
  padding-left: 44px;
  padding-right: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  position: relative;

  /* animation */
  will-change: border-color;
  transition: border-color 300ms;
}

/* hover state */
.step:hover {
  border-bottom-color: var(--secondary);
}

/* active state */
.step.active {
  border-bottom-color: var(--primary);
  font-weight: 700;
}

/* focus state (using 'focus-visible' rather than 'focus' because we only want buttons to have outlines when keyboard navigation was used) */
.step:focus-visible {
  outline: 1px solid var(--text);
}

/* disabled state (using 'aria-disabled' rather than 'disabled' so that buttons aren't hidden from keyboard navigation) */
.step[aria-disabled='true'] {
  cursor: not-allowed;
  color: var(--default);
  border-bottom-color: transparent;
}
