.title {
  grid-area: title;
}

.date {
  grid-area: date;
}

.action {
  grid-area: action;
}

.cardLayout {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-areas: 'title date action';
  align-items: center;
}

.cards {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 0;
}

/* mobile */
@media screen and (max-width: 580px) {
  .cardLayout {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    column-gap: 15px;
    grid-template-areas:
      'title action'
      'date action';
  }
}
