/* input-with-button */
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
  border-radius: 5px;
}

.input {
  flex-grow: 1;
  color: var(--link-text);
  border-top-right-radius: 0px;
  height: 40px;
  border-bottom-right-radius: 0px;
}

.button {
  font-size: 14px;
  font-family: var(--body);
  background-color: transparent;
  white-space: nowrap;
  color: var(--link-text);
  width: 150px;
  height: 40px;
  border: 1px solid var(--borders);
  border-radius: 10px;
  padding: 7px 15px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button:hover {
  background-color: var(--link-text);
  color: var(--black);
}

/* tag-input */
.labelAndTagsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.tagsContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}
