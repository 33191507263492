.content {
  will-change: height, opacity;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 350ms;
}

.contentLoading {
  height: 300px !important;
  opacity: 0;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  opacity: 0;
  will-change: opacity;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955) 350ms;
}

.loaderLoading {
  display: initial;
  opacity: 1;
}
