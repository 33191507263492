.ratingScale {
  padding-right: 30px;
}

.ratingKey {
  border-left: 1px solid var(--borders);
  border-right: 1px solid var(--borders);
  padding: 30px;
}

.ratingKey.dialogContained {
  border: none;
  padding: 0px;
}

.ratingKey:not(.dialogContained)::after {
  content: '';
  display: block;
  position: absolute;
  left: -1px;
  bottom: 0px;
  width: calc(100% + 2px);
  height: 50px;
  background: linear-gradient(to top, var(--background), var(--background), transparent);
}

.ratingFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
}

.ratingBody {
  display: grid;
  grid-template-columns: 1fr 375px;
  border-top: 1px solid var(--borders);
}

/* tablet */
@media screen and (max-width: 930px) {
  .ratingScale {
    padding-right: 0px;
  }

  .ratingBody {
    grid-template-columns: 1fr;
  }
}
