/* OTHER - START */
.navCaption {
  font-size: 1rem;
  line-height: 2.14rem;
  color: var(--text_1);
}

.navIcon {
  width: 25px;
  height: auto;
}

.navList {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.navDivider {
  margin: 20px 10px 20px 10px;
  border-top: 1px solid var(--accent-2);
  width: calc(100% - 20px);
}
/* OTHER - END */

/* AVATAR - START */
.navAvatarIconContainer {
  border: solid 2px var(--accent-2);
  border-radius: 30px;
  overflow: hidden;
}
/* AVATAR - START */
