.container {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
  flex: 1 1 0;
}

.chartContainer {
  width: 200px;
  max-width: 200px;
}

.legendContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Mobile styles */
@media screen and (max-width: 450px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    text-align: left;
    width: 100%;
    padding: 0;
  }

  .chartContainer {
    width: 160px;
    max-width: 160px;
    margin: 0;
  }

  .legendContainer {
    margin-top: 10px;
    width: auto;
    padding: 0;
  }
}
