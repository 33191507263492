.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 130px;
  width: 100%;
  border-bottom: 1px solid var(--borders);
  padding: 15px;
}

.header svg,
.header img {
  height: 100%;
}

.body {
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border-top: 1px solid var(--borders);
}

.card {
  position: relative;
  padding: 130px 0px 50px 0px;
  overflow: hidden;
}
