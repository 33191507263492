.averages {
  display: grid;
  align-items: center;
}

/* non-mobile */
@media screen and (min-width: 581px) {
  .average {
    border-left: 1px solid var(--borders);
    padding: 10px 30px;
  }

  .averages {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px 10px 10px 10px;
  }
}

/* mobile */
@media screen and (max-width: 580px) {
  .average:not(:last-child) {
    border-bottom: 1px solid var(--borders);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .averages {
    grid-template-columns: 1fr;
    margin-top: 15px;
  }
}

