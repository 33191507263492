.companyRolesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.companyRolesActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.companyRolesActionsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.descriptionColumn {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}
