.actionsGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.actionsContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

@media screen and (max-width: 580px) {
  .actionsGroup {
    width: 100%;
  }

  .actionsContainer {
    flex-direction: column;
    gap: 15px;
  }
}
