.thumbnailStartIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0px;
}

.thumbnailFigure {
  margin: 0px;
  height: 100%;
  width: 100%;
}

.thumbnailFigure img,
.thumbnailFigure svg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.thumbnailText {
  position: absolute;
  top: calc(50% + 96px);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 16px;
  background-color: var(--background_1);
  color: var(--text);
  font-weight: 700;
  text-transform: uppercase;
}

.thumbnailButton {
  position: relative;
  border: none;
  height: 50%;
  width: 100%;
  background-color: var(--primary);
  cursor: pointer;
}

.cardName {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  margin: 12px 0px;
}

.cardDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
}

.cardFooter {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.cardFooterText {
  text-transform: uppercase;
  color: var(--default);
}

.playlistButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 100%;
  border: solid 1px var(--borders);
  border-right: none;
  background: var(--borders);
  overflow: hidden;
  cursor: pointer;
  will-change: top;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.playlistButton svg {
  transform-origin: center center;
  transform: rotate(180deg);
  will-change: transform, transform-origin;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.playlistButton.playlistCollapsed svg {
  transform: rotate(0deg);
}

.playlist {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 30px;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  will-change: right;
  transition: right cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.playlist.playlistCollapsed {
  right: -320px;
}

.playlistItem.active {
  border-color: var(--secondary);
}

.playlistItem:hover {
  border-color: var(--contrast-text);
}

.iframe {
  height: 100%;
  width: calc(100% - 335px);
  will-change: width;
  transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
}

.iframe.playlistCollapsed {
  width: calc(100% - 15px);
}

.player {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

/* touchscreen */
@media screen and (pointer: coarse) {
  .playlist {
    width: 100%;
    height: 50%;
    border: none;
    border-top: 1px solid var(--borders);
    top: 50%;
  }

  .playlist.playlistCollapsed {
    right: 0px;
  }

  .iframe {
    width: 100%;
  }

  .iframe.playlistCollapsed {
    width: 100%;
  }

  .player {
    flex-direction: column;
  }
}

/* support */
@supports (aspect-ratio: 16 / 9) {
  .thumbnail {
    padding-top: 0;
    aspect-ratio: 16 / 9;
  }
}
