.subColumn {
  flex: 1;
}

.subContainer {
  display: flex;
  width: 100%;
  gap: 10px;
}

.overallContainer {
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navLink {
  text-decoration: none;
}

.skeletonLoaderContainer {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  min-height: calc(100vh - 90px);
}

@media (max-width: 580px) {
  .container {
    gap: 15px;
  }
}
