.actions {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  gap: 15px;
}

.name {
  max-width: 50ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* mobile */
@media screen and (max-width: 580px) {
  .name {
    white-space: normal;
  }
}
