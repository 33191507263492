.pageContent {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.card {
  padding: 15px 25px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left-width: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.searchRow {
  display: flex;
  gap: 15px;
}

.pagerContainer {
  display: flex;
  justify-content: center;
}

.datesContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  width: auto;
}

.dateText {
  color: var(--text_1);
}

.assessmentTypeDesktop {
  display: block;
  color: var(--text_1);
}

.assessmentTypeMobile {
  display: none;
  color: var(--text_1);
}

.statusContainer {
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: center;
  width: auto;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileTypeContainer {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  padding-bottom: 5px;
}

.assessmentName {
  margin: 0;
}

.assessmentDetailsContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 20px;
}

.assessmentMetricsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.skillContainer {
  display: flex;
  gap: 8px;
}

.tagsContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.tagsCaption {
  color: var(--text_1);
}

.tagsChipsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.viewButtonContainer {
  margin-left: auto;
  align-self: flex-end;
}

/* Media queries for mobile */
@media (max-width: 768px) {
  .assessmentTypeDesktop {
    display: none;
  }

  .assessmentTypeMobile {
    display: flex;
  }

  .mobileTypeContainer {
    display: flex;
  }

  .assessmentMetricsContainer {
    flex-direction: column;
  }

  .datesContainer {
    justify-content: flex-start;
    width: 100%;
  }

  .statusContainer {
    width: 100%;
  }
}
