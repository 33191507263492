.checkIconBackground {
  width: 25px;
  height: 25px;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.done {
  border-color: var(--apple);
}
