.listItem {
  width: 30px;
  height: 5px;
  background: transparent;
  border: solid 1px var(--chip-color);
  border-radius: 5px;
  position: relative;
}

.listItem.filled {
  background: var(--chip-fill);
  opacity: 1;
}

.list {
  --chip-color: var(--primary);
  --chip-fill: var(--chip-color);

  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.list.red {
  --chip-color: var(--r);
}

.list.yellow {
  --chip-color: var(--a);
}

.list.green {
  --chip-color: var(--g);
}
