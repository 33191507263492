.logo {
  height: 100%;
}

.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  border-bottom: solid 1px var(--background);
  padding: 20px;
  background-color: var(--background);
  will-change: border-bottom, background-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
}

.nav.scrolled {
  border-bottom-color: var(--borders);
  background-color: var(--cards);
}
