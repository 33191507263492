.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 20px 45px;
  width: 100%;
}

.content {
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: auto 1fr 1fr auto;
  min-width: min-content;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}

