.progressBar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 100%;
}

.captions {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  color: black;
}

.skillLevelIndicator {
  margin-top: 5px;
  white-space: nowrap;
}

.skillScoreContainer {
  display: flex;
  gap: 40px;
  margin: 5px 0;
}

.skillScoreContainer > :nth-child(2) {
  border-left: 1px solid var(--default);
  padding-left: 40px;
}

.row {
  width: 100%;
  height: auto;
  margin-top: 80px;
  position: relative;
  z-index: 1;
}

.innerRow {
  width: 90%;
  height: auto;
  margin: auto;
}

.innerRow img {
  display: block;
  margin: auto;
  cursor: pointer;
}

.cardRow {
  display: block;
  position: absolute;
  bottom: calc(100% + 50px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
}

.cardRow::before,
.cardRow::after {
  content: '';
  display: block;
  position: absolute;
}

.cardRow::before {
  height: 80px;
  width: 2px;
  background: var(--white);
  top: 100%;
  left: 50%;
  margin-left: -1px;
}

.cardRow::after {
  width: 16px;
  height: 16px;
  background: var(--white);
  border-radius: 50%;
  top: calc(100% + 80px);
  left: 50%;
  margin-left: -8px;
  border: solid 2px var(--primary);
  outline: solid 4px rgba(255, 255, 255, 0.6);
}

.cardRowInner {
  padding: 12px 20px 12px 15px;
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  min-width: 375px;
  max-width: 400px;
  position: relative;
  transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
}

.cardRowInner::before {
  content: '';
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.cardInnerText {
  font-weight: 700;
  color: black;
  max-width: 280px;
}

.cardChip {
  text-align: left;
  margin-top: 4px;
}

.cardActions {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
