.errorPageFigure {
  display: block;
  padding: 0;
  margin: 0;
}

.errorPageFigure object,
.errorPageFigure img {
  display: block;
  width: 100%;
  max-width: 550px;
  margin-left: auto;
}

.errorPageFigure.wide object,
.errorPageFigure.wide img {
  max-width: 700px;
}

@media screen and (max-width: 950px) {
  .errorPageFigure object,
  .errorPageFigure img {
    margin: auto;
    width: 90% !important;
    max-width: 100% !important;
  }
}

.errorPageHeadingText {
  color: var(--white);
}

.errorPageSubtitleText {
  padding-top: 10px;
  padding-bottom: 25px;
  color: var(--grc_1);
}

.errorPageBodyText {
  color: var(--white);
  padding-bottom: 15px;
}

@media screen and (max-width: 950px) {
  .errorPageContent {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
}

.errorPageContainer {
  width: 100%;
  min-height: calc(100vh - 92px);
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 50px;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

@media screen and (max-width: 950px) {
  .errorPageContainer {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-end;
    gap: 5px;
  }
}

.errorPageBackground {
  margin: auto;
  padding: 0;
  height: auto;
  width: 100%;
  background-color: #0c1326;
  background-image: url('/public/backgrounds/space-background.png');
  background-size: cover;
}
