/* OTHER - START */
.sideNavDialogHeader {
  margin: 20px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.sideNavDialogContent {
  position: fixed;
  top: 0;

  height: 100vh;
  width: 80%;
  padding: 10px;

  background-color: var(--background);
}

.sideNavDialogContent[open] {
  animation: SideNavDialogSlideIn 500ms ease forwards;
}

.sideNavDialogContent[open]::backdrop {
  animation: SideNavDialogFade 500ms ease forwards;
}

.sideNavDialogContent.close {
  animation: SideNavDialogSlideOut 500ms ease backwards;
}

.sideNavDialogContent.close::backdrop {
  animation: SideNavDialogFade 500ms ease backwards;
  animation-direction: reverse;
}
/* OTHER - END */

/* AVATAR - START */
.sideNavDialogAvatarIconContainer {
  width: 60px;
  height: 60px;
}
/* AVATAR - END */

/* SWITCH TO - START */
.sideNavDialogSwitchToContainer {
  margin: 20px 0;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  background-color: var(--cards);
}
/* SWITCH TO - END */

/* YOUR ACCOUNT - START */
.sideNavDialogYourAccount {
  border: none;
  width: 100%;
  background-color: var(--background);
  display: flex;
  align-items: center;
  color: var(--default);
  text-decoration: none;
  cursor: pointer;
}
/* YOUR ACCOUNT - END */

/* ROUTES - START */
.sideNavDialogParentRoute {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: var(--cards);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  color: var(--text);
  font-weight: 400;
  text-decoration: none;
}

.sideNavDialogParentRoute.keplerActive {
  background-color: var(--primary);
  color: var(--contrast-text);
  font-weight: 700;
}
/* ROUTES - END */

@keyframes SideNavDialogSlideIn {
  from {
    left: 100%;
  }
  to {
    left: 20%;
  }
}

@keyframes SideNavDialogFade {
  from {
    background-color: transparent;
    backdrop-filter: blur(0px);
  }
  to {
    background-color: hsla(0, 0%, 0%, 0.8); /* TODO: try var() again in the future, not supported at the moment */
    backdrop-filter: blur(1px);
  }
}

@keyframes SideNavDialogSlideOut {
  from {
    left: 20%;
  }
  to {
    left: 100%;
  }
}
