.charts {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
}

.chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 580px) {
  .charts {
    flex-direction: column;
  }
}
