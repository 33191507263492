.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  min-width: 200px;
  height: 100%;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-right: 1px solid var(--borders);
}

.body {
  position: relative;
  align-items: flex-start;
  justify-content: center;
  gap: 0;
  min-height: 54px;
  text-align: left;
}

@supports (aspect-ratio: 16 / 9) {
  .image {
    aspect-ratio: 16 / 9;
  }
}
