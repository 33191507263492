.card {
  grid-template-columns: 1fr auto;
  background-color: var(--cards_2);
}

.progressBarContainer {
  grid-area: progress;
  background: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.progressBar {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;
}

.progressBar::-webkit-progress-bar {
  background-color: var(--borders);
}

.progressBar::-webkit-progress-value {
  background: var(--secondary);
}
