.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: var(--secondary);
}

.title {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--background);
}

.figure {
  position: relative;
  display: flex;
  justify-content: center;
}

/* TODO: use this when rewriting Anchor */
.anchor {
  display: inline;
  font-size: 0.89rem;
  text-transform: uppercase;
  color: var(--link-text);
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchor:hover {
  box-shadow: 0 0.05rem var(--cards), 0 0.15rem var(--primary);
}

.card {
  position: relative;
  height: 100%;
  padding: 15px;
  padding-top: 40px;
  overflow: hidden;
}
