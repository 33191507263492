.companySkillsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.companySkillsActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.companySkillsActionsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.descriptionColumn {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  white-space: normal;
}

@media (max-width: 580px) {
  .companySkillsActions {
    gap: 15px;
  }
}
