.avatar {
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  height: 130px;
  overflow: hidden;
}

.person {
  display: grid;
  grid-template-columns: 130px auto;
  align-items: center;
  gap: 20px;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.keplerPoints {
  width: fit-content;
  height: fit-content;
}

.container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 590px) {
  .keplerPoints {
    width: 100%;
  }

  .avatar {
    height: 100px;
  }

  .person {
    grid-template-columns: 100px auto;
  }

  .actions {
    justify-content: flex-start;
  }

  .container {
    gap: 15px;
  }
}

