.peopleNameCell {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.peopleActions {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(135px, 300px) 1fr auto auto auto;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 580px) {
  .peopleActions {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .peopleNameCell {
    flex-flow: row-reverse nowrap;
  }
}
