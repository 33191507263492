.scrollContainer {
  margin-top: 15px;
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
}

.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
