/* flag-icon.tsx */
@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

/* App.tsx */
main {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 90px;
  background-color: var(--background);
  overflow: hidden;
  will-change: padding-left, opacity;
  transition: padding-left cubic-bezier(0.455, 0.03, 0.515, 0.955) var(--font-weight-normal) ms, opacity cubic-bezier(0.55, 0.085, 0.68, 0.53) 300ms, transform cubic-bezier(0.55, 0.085, 0.68, 0.53) 300ms;
}

/* general */
#hubspot-bot {
  position: fixed;
  top: 80px;
  right: 20px;
}

#hubspot-conversations-inline-iframe {
  width: 300px;
  height: 500px;
  border: none;
}

.skeleton-loader-container {
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

/* TODO: remove when deploying */
:root {
  font-size: 14px;
}