.card {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
}

/* mobile */
@media (max-width: 580px) {
  .card {
    width: 100%;
    max-width: 300px;
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }
}
