.burger {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 200px;
  border: 1px solid var(--borders_1);
  border-radius: 15px;
  padding: 10px 0;
  background: var(--cards);
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(5px);
  animation: TopNavMenuEnter cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms forwards;
}

.title {
  padding-left: 10px;
}

.item {
  border: none;
  padding-left: 10px;
  width: 100%;
  background-color: var(--cards);
  display: flex;
  align-items: center;
  font-family: var(--body);
  text-decoration: none;
  cursor: pointer;
}

.item:hover {
  border-color: var(--accent-2_2);
  background-color: var(--accent-2_2);
}

.item.isChecked {
  background-color: var(--background);
}

/* tablet */
@media (min-width: 930px) {
  .burger {
    display: none;
  }
}

/* animation */
@keyframes TopNavMenuEnter {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
