.name {
  min-width: 30%;
  max-width: 30%;
}

/* tablet */
@media screen and (max-width: 930px) {
  .name {
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
}
