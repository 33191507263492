.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 92px);
  overflow: hidden;
  pointer-events: all;
  opacity: 1;
  will-change: opacity;
  z-index: 99;
}

.loader.hidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms;
}
