.bodyLayout {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 20px;
}

.headerImage > img {
  width: 270px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .bodyLayout {
    padding: 20px 20px;
  }
  
  .headerImage > img {
    width: 100%;
  }
}
