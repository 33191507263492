.loader {
  margin: auto;
  text-align: center;
}

.loader > object,
.loader > img {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  max-width: 200px;
}