.scrollContainer {
  max-height: 400px;
  overflow-y: auto;
}

.card {
  display: grid;
  grid-template-areas: 'name chip' 'description chip';
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
}

.description {
  grid-area: description;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 580px) {
  .card {
    grid-template-areas: 'name chip' 'description description';
    grid-template-columns: 1fr auto;
  }
}
