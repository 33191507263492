.radioButtonBackground {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--colour);
  border-radius: 50%;
  position: relative;
  transform: scale(0.9);
  opacity: 0;
  will-change: transform, opacity;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
}

.radioButtonBackground svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.radioButton {
  width: 42px;
  height: 42px;
  border: solid 1px var(--default);
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  padding: 2px;
  transition: border-color cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;
}

.label {
  font-size: 12px;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 20px;
  padding-top: 7px;
}

.input {
  position: absolute;
  cursor: pointer;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.input:checked + .radioButton {
  border-color: var(--colour);

  span {
    opacity: 1;
    transform: scale(1);
  }
}

.listItem {
  max-width: 70px;
  flex: 1;
  height: auto;
  position: relative;
}

.list {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-between;
  flex: 1;
}

.list.faded {
  opacity: 0.5;
}

@media screen and (max-width: 580px) {
  .label {
    font-size: 10px;
  }

  .list {
    max-width: 100% !important;
    width: 100% !important;
    margin: auto;
  }
}
