.form {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  margin: auto;
  padding-top: 60px;
}

@media screen and (max-width: 580px) {
  .form {
    padding-top: 30px;
  }
}
.scrollableContainer {
  max-height: calc(100vh - 200px); 
  overflow-y: auto;
  padding-bottom: 20px; 
}
