.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 25px 0;
}
