.placeholder {
  border: 1px solid var(--secondary);
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.placeholder.active {
  background: var(--secondary);
}

.placeholder.active::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 14px;
  height: 8px;
  margin: -6px -7px;
  border: solid 3px var(--background);
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}

.day {
  width: auto;
  height: auto;
  display: grid;
  place-content: center;
}

.day:not(:first-child) {
  margin-left: auto;
}

.day:last-child {
  margin-right: auto;
}

.days {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
