.figure {
  max-width: 640px;
  width: 640px;
  flex-basis: 640px;
  padding: 0;
  margin: 0;
  display: block;
}

.image object,
.image img {
  display: block;
  width: 100%;
  height: auto;
}

.subtitle {
  padding: 15px 0 25px 0;
  line-height: 30px;
}

.content {
  flex: 1;
  max-width: calc(100% - 640px);
  width: calc(100% - 640px);
  flex-basis: calc(100% - 640px);
}

.section {
  display: flex;
  align-items: center;
  gap: 30px;
}

/* tablet */
@media screen and (max-width: 930px) {
  .figure {
    display: none;
  }

  .content {
    max-width: 100%;
  }

  .page {
    width: 90%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* mobile */
@media screen and (max-width: 580px) {
  .section {
    padding-top: 10px;
  }

  .title {
    line-height: 35px;
  }

  .subtitle {
    line-height: 20px;
  }

  .content {
    padding: 0;
  }
}
