.radioButtons {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.card.fade {
  opacity: 0.45;
}

/* mobile */
@media screen and (max-width: 580px) {
  .radioButtons {
    flex-flow: column nowrap;
    align-items: stretch;
  }
}
