.ssoButton {
  display: inline-flex;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;
  background: transparent;
}

.ssoButton img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}