.filtersGroup {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
}

@media screen and (max-width: 530px) {
  .filtersGroup {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: 15px;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.footer {
  display: flex;
  width: 100%;
  z-index: 10;
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  border: 1px solid var(--borders);
  background: var(--cards);
  backdrop-filter: blur(10px);
  will-change: width;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  min-height: 110px;
}

@media screen and (max-width: 930px) {
  .footer {
    min-height: 0;
    padding: 15px 20px;
  }
}

.wizardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--background);
}
