.content {
  text-decoration: 'none';
}

@media (max-width: 580px) {
  .content {
    grid-template-columns: auto auto auto;
    padding: 20px 10px;
  }
}

