.focusPanelLoaderLayerLoader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  width: 100%;
  height: 100vh;
  overflow: hidden;

  background: var(--background);

  pointer-events: all;
  opacity: 1;
  will-change: opacity;
}

.focusPanelLoaderLayerLoader.hidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms;
  overflow-y: auto;
}

.focusPanelLoaderLayerContainer {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 92px);
  margin: auto;
  overflow-y: auto;
}

.focusPanelLoaderLayerContainer.loading {
  overflow-y: hidden;
}
