.skillCard {
  display: grid;
  align-items: center;
  gap: 10px;
}

.skillCardText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
