.skillsReportContainer {
  width: 100%;
}

.skillsReportContentTabSlide {
  padding: 1rem 1rem;
}

.skillsReportCard {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 2rem;
}

.skillsReportContentSunburst {
  flex: 0 0 auto;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.skillsReportContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.skillsReportContentHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.skillsReportContentHeader h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
}

.skillsReportContentScore {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;
}

.skillsReportContentLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillsReportContentList {
  flex: 1 1 auto;
}

.skillsReportContentList h4 {
  margin-bottom: 0.5rem;
}

.skillItemList {
  max-height: 300px;
  overflow-y: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
}

.td {
  padding: 5px;
  border-top: solid 1px var(--borders);
  border-bottom: solid 1px var(--borders);
  text-align: left;
}

.td:first-child {
  border-left: solid 1px var(--borders);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 10px;
}

.td:last-child {
  border-right: solid 1px var(--borders);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  min-width: 0; 
}

.listItemColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0; 
}

.listItemName {
  font-size: 0.9rem;
  min-width: 0; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skillsReportContentActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .skillsReportCard {
    flex-direction: column;
  }

  .skillsReportContentSunburst {
    width: 100%;
    height: 250px;
  }

  .skillsReportContentHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .skillsReportContentScore {
    width: 100%;
  }
}
