.avatar {
  width: 25px;
  height: 25px;
  border: solid 1px var(--accent-2);
  border-radius: 50%;
  overflow: hidden;
}

.dropdownListItem {
  gap: 10px;
  background-color: var(--background);
  color: var(--text);
  padding: 10px;
}

.dropdownListItem:hover {
  background-color: var(--cards);
}

.listItem {
  display: flex;
  width: 100%;
  margin-top: 5px;
  align-items: center;
}

.listItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.selectedOptionsTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: center;
}
