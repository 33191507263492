.legend {
  /* TODO: negative margin is never ideal, we need to look at why this was needed */
  /* margin-left: -20px; */
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--borders);
  padding: 5px 10px;
  height: 30px;
  text-wrap: nowrap;
  border-radius: 15px;
  background-color: var(--background);
}

.verticalLegend {
  flex-direction: column;
  align-items: start;
}

@media screen and (max-width: 760px) {
  .verticalLegend {
    align-items: flex-start;
    width: fit-content;
    padding: 0;
    margin: 0;
    gap: 5px;
  }
  
  .legend {
    margin: 0 0 10px 0;
    padding: 0;
    width: fit-content;
  }
  
  .legendItem {
    padding: 3px 8px;
    height: 26px;
    margin: 0;
  }
}