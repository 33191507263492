.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding-top: 24px;
  height: 100%;
}

.chips {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
