.profileHeaderIcon {
  width: 150px;
  height: 150px;
  border: solid 3px var(--text);
  border-radius: 50%;
  overflow: hidden;
}

.profileHeaderIcon img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profileHeader {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
  align-items: center;
}

.profileCardHeaderTitle {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.profileCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileCardAboutItem {
  padding: 5px 0px;
}

.profileBody {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'about contact' 'about security';
  gap: 30px;
}

/* tablet */
@media screen and (max-width: 930px) {
  .profileHeader {
    gap: 15px;
  }

  .profileBody {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'about' 'contact' 'security';
  }
}

/* mobile */
@media screen and (max-width: 580px) {
  .profileHeaderIcon {
    width: 60px;
    height: 60px;
  }

  .profileHeaderIcon img {
    width: 60px;
    height: 60px;
  }
}
