/* TODO: refactor to match input styles */

/* input */
.output {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 12px;
  color: var(--accent-2);
}

.label {
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 10px;
  color: var(--accent-3);
  font-weight: 500;
  font-family: var(--heading);
  cursor: pointer;
}

.input {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: 45px;
  background: var(--cards);
  border: 1px solid var(--borders);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 5px 0 10px;
  cursor: pointer;
  flex: 0 0 auto;
  gap: 5px;
  flex: 1;
}

.input.hasError {
  border-color: var(--validation);
}

/* dialog */
.day {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--text);
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px transparent;
}

.day:hover {
  border-color: var(--text);
}

.day.overflow {
  color: var(--text_2);
}

.day.today {
  border-color: transparent;
  background: var(--primary_2);
}

.day.selected {
  border-color: transparent;
  background: var(--primary_1);
  color: var(--contrast-text);
}

.day.disabled {
  cursor: not-allowed;
  color: var(--background_2);
  border-color: transparent;
  opacity: 0.4;
  user-select: none;
}

.week {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.week:last-child {
  padding-bottom: 0;
}

.listItem {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--accent-3);
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 5px 0;
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 15px 0;
  user-select: none;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 10px 0;
}

.dialog {
  position: absolute;
  width: 330px;
  top: 0;
  left: 50%;
  z-index: 9999;
  padding: 25px 15px;
  border: 1px solid var(--borders);
  background: var(--cards);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  transform: translateX(-50%);
  animation: DropdownIn forwards 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 45px;
}

.dialog.fixed {
  position: fixed;
  width: 100%;
  max-width: 330px;
}

.dialog.autoHeight {
  min-height: unset;
}

.dialog.openAbove {
  top: auto;
  bottom: 0;
}

@media screen and (max-width: 530px) {
  .dialog:not(.fixed) {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--backdrop);
  z-index: 9998;
}

@keyframes DropdownIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
