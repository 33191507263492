.avatar {
  margin: 0px;
  border: solid 2px var(--accent-2);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
}

.content.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: start;
  gap: 15px;
}

.card {
  display: grid;
  grid-template-columns: 70px minmax(0, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
