.legendOption {
  --legend-option-colour: var(--primary);

  position: relative;
  padding-left: 15px;
  opacity: 0.5;
  cursor: pointer;
}

.legendOption::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  display: block;
  margin-top: -5px;
  border-radius: 1px;
  width: 10px;
  height: 10px;
  background-color: var(--legend-option-colour);
}

.legendOption.active {
  opacity: 1;
}

.legendOption.disabled {
  cursor: not-allowed;
}

.legend {
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}
