.actions {
  display: grid;
  gap: 15px;
}

.button {
  color: var(--text);
  text-align: left;
}

.card.selected,
.button.selected {
  border-color: var(--secondary);
}

.card.disabled,
.button:disabled {
  border-color: var(--default);
  color: var(--default);
  cursor: not-allowed;
}

.button:not(:disabled):hover {
  cursor: pointer;
  outline: 1px solid var(--text);
}

@media (max-width: 580px) {
  .actions {
    grid-template-columns: auto;
  }
}
