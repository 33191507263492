.searchContainer {
  margin: 15px 0px;
}
.scrollableList {
  max-height: 400px;
  overflow-y: auto;
}
.card {
  border-left-width: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.cardColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}