/* ALL COMPANIES - START */
.sideNavAllCompanies {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  font-weight: 500;
  font-family: var(--body);
  line-height: 1.57rem;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--secondary);
  white-space: nowrap;

  cursor: pointer;
}

.sideNavAllCompaniesContainer {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  height: 25px;
}
/* ALL COMPANIES - END */

/* ROUTES - START */
.sideNavChildRoute {
  display: block;

  width: 100%;
  padding: 5px 25px 5px 0;

  color: var(--text);
  font-family: var(--body);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.57rem;
  text-decoration: none;
}

.sideNavChildRoute.keplerActive {
  color: var(--secondary);
  font-weight: 700;
}

.sideNavChildRouteListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideNavChildRouteListItem::after {
  content: '';

  transform: rotate(45deg);

  width: 10px;
  height: 10px;
  border: solid 2px var(--primary);
  border-left: none;
  border-bottom: none;
}

.sideNavParentRouteTitle {
  text-transform: uppercase;
  font-size: 1rem;
}
/* ROUTES - END */
