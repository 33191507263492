.card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  align-items: center;
  gap: 4px;
}

@media (max-width: 580px) {
  .card {
    grid-template-columns: 1fr;
  }
}
