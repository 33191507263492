.accountNavigationToggler {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.accountNavigationToggler span {
  margin-top: 2px;
}

.accountNavigationToggler.bottom {
  justify-content: center;
}

.accountDivider {
  text-align: center;
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--text);
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 330px;
  margin: auto;
  margin-bottom: 15px;
}

.accountDivider::before,
.accountDivider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--accent-2);
}

.accountDivider:not(:empty)::before {
  margin-right: 0.5em;
}

.accountDivider:not(:empty)::after {
  margin-left: 0.5em;
}

.accountInstructions {
  padding: 30px 0;
}

.accountForm {
  position: relative;
  margin: auto;
  max-width: 330px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  justify-content: center;
  gap: 10px;
}

.accountCard {
  padding: 40px 20px;
  position: relative;
}

.accountPage {
  width: 90%;
  height: auto;
  text-align: center;
  margin: auto;
  max-width: 700px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.accountBackground {
  width: 100%;
  background-repeat: repeat-x;
  background-position: bottom;
  min-height: calc(100vh - 90px);
}

/* tablet */
@media screen and (max-width: 930px) {
  .accountBackground {
    min-height: calc(100vh - 75px);
  }
}

/* mobile */
@media screen and (max-width: 580px) {
  .accountNavigationToggler {
    bottom: 20px;
    top: unset;
    margin: auto;
    width: 100%;
    right: 0;
  }

  .accountInstructions {
    padding: 0px;
  }

  .accountCard {
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .accountPage {
    height: calc(100vh - 126px);
    padding: 10px 0 30px 0;
    min-height: 100vh;
  }
}
