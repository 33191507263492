.chartTitle {
  text-wrap: no-wrap;
  padding-bottom: 10px;
}
.emptyStateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
