.title {
  line-height: 20px;
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.keplerPoints {
  text-align: end;
  white-space: nowrap;
}

.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 140px;
  align-items: center;
}

.delete {
  grid-area: delete;
}

.edit {
  grid-area: edit;
}

.card {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-areas: 'delete content edit';
  grid-template-rows: auto;
  gap: 15px;
  align-items: center;
}

/* mobile */
@media screen and (max-width: 580px) {
  .keplerPoints {
    text-align: start;
  }

  .content {
    display: block;
  }

  .card {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas: 'content content content' 'delete . edit';
    gap: 0px;
    padding-bottom: 0px;
  }
}
